<template>
  <v-container class="wrapper pa-6" fluid fill-height>
    <div class="main elevated">
      <streaming-not-started v-if="isStreamingNotStarted" @start="start" />
      <streaming-waiting-play v-else-if="isStreamingRunning && !userPressedPlay" @play="userPressedPlay = true" />
      <div v-else-if="isStreamingRunning && userPressedPlay" class="video-call-wrapper">
        <div class="badge">
          <img :src="require('@/assets/logo.svg')">
        </div>
        <streaming-video-call :channel="data.id" :role="role" @finish="finish" />
      </div>
      <streaming-finished v-else-if="isStreamingFinished" />
    </div>
    <streaming-sidebar class="elevated" />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { auth } from '@/services/firebase'

export default {
  name: 'Streaming',
  components: {
    StreamingVideoCall: () => import('@/modules/streaming/StreamingVideoCall'),
    StreamingWaitingPlay: () => import('@/modules/streaming/StreamingWaitingPlay'),
    StreamingNotStarted: () => import('@/modules/streaming/StreamingNotStarted'),
    StreamingFinished: () => import('@/modules/streaming/StreamingFinished'),
    StreamingSidebar: () => import('@/modules/streaming/StreamingSidebar'),
  },
  props: {
    organizationId: { type: String, required: true },
    projectId: { type: String, required: true },
    activityId: { type: String, required: true },
  },
  data() {
    return {
      userPressedPlay: false,
      role: 'host',
      isDataLoaded: false,
    }
  },
  computed: {
    ...mapGetters({ activityData: 'activity/data' }),
    ...mapGetters('streaming', ['data']),
    isStreamingNotStarted: ({ data }) => data?.status === 'notStarted',
    isStreamingFinished: ({ data }) => data?.status === 'finished',
    isStreamingRunning: ({ data }) => data?.status === 'running',
  },
  methods: {
    start() {
      this.userPressedPlay = true
      const { organizationId, data } = this
      if (!auth.currentUser) {
        this.$store.dispatch('alert/openAlertBox', ['alertError', this.$t('streaming.notLoggedInError')])
        return
      }
      this.runAsync(() => this.$store.dispatch('streaming/start', { organizationId, streamingId: data.id }))
    },
    finish() {
      const { organizationId, data } = this
      if (!auth.currentUser) {
        this.$store.dispatch('alert/openAlertBox', ['alertError', this.$t('streaming.notLoggedInError')])
        return
      }
      this.runAsync(() => this.$store.dispatch('streaming/finish', { organizationId, streamingId: data.id }))
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  max-height: 100vh;
  min-width: 800px;
  overflow-x: scroll;
}

.elevated {
  border-radius: 8px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
}

// Main area, where video call plays
.main {
  flex: 1;
  height: 100%;
  margin-right: 24px;
  overflow: hidden;
}

.main .video-call-wrapper {
  position: relative;
  height: 100%;

  .badge {
    position: absolute;
    top: 10%;
    left: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    width: 130px;
    height: 42px;
    padding: 12px;
    background-color: white;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
  }
}
</style>
